import { Address, NetworkType } from 'tsjs-xpx-chain-sdk';

export class SiriusIdService {
  // User-related Info
  pubKey = ""
  address = "";
  credentialInfo = null;

  // Chain Connection Info
  endpoint = "bctestnet1.brimstone.xpxsirius.io";
  api = "https://bctestnet1.brimstone.xpxsirius.io";
  ws = "wss://bctestnet1.brimstone.xpxsirius.io";
  networkType = NetworkType.TEST_NET;
  generationHash = "56D112C98F7A7E34D1AEDC4BD01BC06CA2276DD546A93E36690B785E82439CA9";

  // Storage Connection Info
  storageEndpoint = "ipfs1-dev.xpxsirius.io";

  // SiriusID Platform Info

  // CityID
  idPubKey = "4017DDCEED15C2A8F0032251017ED045322C418707003A63540A6F9942720A80";
  idPriKey = "C8CE2F2E7E2AFA0BDC2BBECC4AA10A41508327BAE0A584985EA6EB4F5818BAC9";
  idAddress = Address.createFromPublicKey(this.idPubKey, this.networkType);

  // Passport
  passportPubKey = "949EA464C9D7AB425443973B4555A0E692776CD5A696DA40D548FE0E1B9CEBA9";
  passportPriKey = "2C89CF31CE574A581EF55D89AD70DF20EE25F9B9E3852437A153ED31572FC78E";
  passportAddress = Address.createFromPublicKey(this.passportPubKey, this.networkType);

  // Bank
  bankPubKey = "10F4337C7A862A247435167AEF4BD37BF5FAE8BCB247A8B3B3B1A616F9224708";
  bankPriKey = "FC4979544C6D504AE8181FB0CD23C68FB345FB3FDC3DC232D419AAF2D3581248";
  bankAddress = Address.createFromPublicKey(this.bankPubKey, this.networkType);

  // Employment
  employmentPubKey = "1130DC883896DB996D59C2CBCEE55F845E95AC70B1F522FC44A5705B160372A0";
  employmentPriKey = "7D94BB4D0C0B90591E7F05F57AE1216892B55EAE4F29B6B00B26F1356F87A96A";
  employmentAddress = Address.createFromPublicKey(this.employmentPubKey, this.networkType);

  // Visa
  visaPubKey = "FF9151BB0D3F6386BE3A9F35B3C7A5247A397804BC059D1F05B2FED06F51805A";
  visaPriKey = "B607FBD8536A742A4BD02B9029E7B59CA612B727E29E81805DBFE441929AD433";
  visaAddress = Address.createFromPublicKey(this.visaPubKey, this.networkType);

  // Immunity
  immunityPubKey = "949EA464C9D7AB425443973B4555A0E692776CD5A696DA40D548FE0E1B9CEBA9";
  immunityPriKey = "2C89CF31CE574A581EF55D89AD70DF20EE25F9B9E3852437A153ED31572FC78E";
  immunityAddress = Address.createFromPublicKey(this.immunityPubKey, this.networkType);

}
