<template>
  <v-app>
    <v-main>
      <Logo v-if="showTitle" v-on:get-started-button="getStartedButton" />
      <HowTo v-if="showHelp" v-on:start-city-button="helpContinueButton" />
      <City v-if="showCity" v-on:finish-city-button="cityFinishButton" />
      <GetInTouch v-if="showContact" />
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Logo from "./components/Logo";
import HowTo from "./components/HowTo";
import City from "./components/City";
import GetInTouch from "./components/GetInTouch";
import Footer from "./components/Footer";

export default {
  name: "App",

  components: {
    Logo,
    HowTo,
    City,
    GetInTouch,
    Footer,
  },

  data: function () {
    return {
      showTitle: true,
      showHelp: false,
      showCity: false,
      showContact: false,
    };
  },
  methods: {
    getStartedButton() {
      this.showTitle = false;
      this.showHelp = true;
    },
    helpContinueButton() {
      this.showHelp = false;
      this.showCity = true;
    },
    cityFinishButton() {
      this.showCity = false;
      this.showContact = true;
    },
  },
};
</script>
