<template>
  <section id="city-id">
    <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-spacer />
      <v-btn
        icon
        v-on:click="
          $emit('close-id');
          transitionReset();
        "
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-app-bar>
    <br />
    <v-container id="city-id-1" v-if="sceneIntro">
      <v-stepper value="1">
        <v-stepper-header>
          <v-stepper-step step="1"> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2"> SiriusID Connection</v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> CityID Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />

      <v-row justify="center">
        <v-img
          src="../assets/city/id/scene.png"
          min-height="65vh"
          max-height="65vh"
          contain
        />
      </v-row>

      <br />

      <v-row justify="center">
        <div class="text-body mx-2">
          <strong>Visitor</strong>: This is the first stop - a CityID is the
          main ID document to get around ProximaXCity.
        </div>
      </v-row>

      <br />

      <v-row justify="center" class="pb-6">
        <v-btn @click="transitionEnter()"
          >Enter <v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </v-row>
    </v-container>
    <v-container id="city-id-1-1" v-if="sceneWelcome">
      <v-stepper value="1">
        <v-stepper-header>
          <v-stepper-step step="1"> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2"> SiriusID Connection</v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> CityID Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />

      <v-row justify="center">
        <v-img
          src="../assets/city/id/staff.png"
          contain
          max-width="525"
          height="475"
          class="hidden-sm-and-down"
        />
        <v-img
          src="../assets/city/id/staff.png"
          contain
          max-width="300"
          height="475"
          class="hidden-md-and-up"
        />
      </v-row>
      <br />
      <v-row justify="center">
        <div class="text-body mx-2">
          <strong>NIRO</strong>: Welcome to the ProximaX City ID Department. To
          obtain a City ID, please connect your SiriusID app by scanning the
          following QR Code.
        </div>
      </v-row>

      <br />

      <v-row justify="center" class="pb-6">
        <v-btn @click="transitionScan()"
          >Next <v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </v-row>
    </v-container>
    <v-container id="city-id-2" v-if="sceneScan">
      <v-stepper value="2">
        <v-stepper-header>
          <v-stepper-step step="1" complete> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2"> SiriusID Connection</v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> CityID Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />

      <v-row justify="center">
        <div class="text-body-1 px-6 py-4 text-center">
          To begin creating your CityID, please download the SiriusID app and
          scan or tap on the QR Code.
        </div>
      </v-row>
      <br />
      <v-row justify="center">
        <v-sheet color="white" elevation="2" height="300" width="300">
          <a :href="this.qrLink" target="_blank" rel="noreferrer noopener">
            <v-img :src="this.qrImg" :alt="this.qrLink">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey"
                    size="100"
                    width="5"
                  />
                </v-row>
              </template>
            </v-img>
          </a>
        </v-sheet>
      </v-row>
      <br />
      <v-row justify="center">
        <v-btn text @click="refresh()"
          ><v-icon>mdi-refresh</v-icon>Refresh Code</v-btn
        >
      </v-row>
      <br />
      <v-row justify="center">
        <div class="text-body-2">Get the SiriusID App</div>
      </v-row>
      <br />
      <v-row justify="center" no-gutters class="hidden-sm-and-down">
        <v-spacer cols="4" />
        <v-col cols="2">
          <v-row justify="center" align="center">
            <a
              href="https://apps.apple.com/us/app/proximax-siriusid/id1496615202?itsct=apps_box&amp;itscg=30200"
              target="_blank"
              rel="noreferrer noopener"
            >
              <v-img
                src="../assets/app-store-badge.svg"
                contain
                width="150"
                height="45"
              />
            </a>
          </v-row>
        </v-col>
        <v-col cols="2">
          <v-row justify="center" align="center">
            <a
              href="https://play.google.com/store/apps/details?id=io.proximax.siriusID&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank"
              rel="noreferrer noopener"
            >
              <v-img
                src="../assets/google-play-badge.png"
                contain
                width="150"
                height="45"
              />
            </a>
          </v-row>
        </v-col>
        <v-spacer cols="4" />
      </v-row>
      <v-row justify="center" no-gutters class="hidden-md-and-up">
        <v-spacer cols="2" />
        <v-col cols="3">
          <v-row justify="center" align="center">
            <a
              href="https://apps.apple.com/us/app/proximax-siriusid/id1496615202?itsct=apps_box&amp;itscg=30200"
              target="_blank"
              rel="noreferrer noopener"
            >
              <v-img
                src="../assets/app-store-badge.svg"
                contain
                width="150"
                height="45"
              />
            </a>
          </v-row>
        </v-col>
        <v-spacer cols="2" />
        <v-col cols="3">
          <v-row justify="center" align="center">
            <a
              href="https://play.google.com/store/apps/details?id=io.proximax.siriusID&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank"
              rel="noreferrer noopener"
            >
              <v-img
                src="../assets/google-play-badge.png"
                contain
                width="150"
                height="45"
              />
            </a>
          </v-row>
        </v-col>
        <v-spacer cols="2" />
      </v-row>
    </v-container>
    <v-container id="city-id-3" v-if="sceneForm">
      <v-stepper value="3">
        <v-stepper-header>
          <v-stepper-step step="1" complete> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2" complete>
            SiriusID Connection</v-stepper-step
          >
          <v-divider />
          <v-stepper-step step="3"> CityID Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />

      <v-row justify="center" class="px-6"
        >The following details are required to create your City ID</v-row
      >
      <br />
      <v-form>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="firstName"
                :rules="nameRules"
                label="First Name"
                required
                readonly
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="lastName"
                :rules="nameRules"
                label="Last Name"
                required
                readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="address"
                :rules="addressRules"
                label="Address"
                required
                readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="phoneNumber"
                :rules="phoneRules"
                label="Phone Number"
                required
                readonly
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="Email"
                required
                readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-menu
                ref="dobMenu"
                v-model="dobMenu"
                :close-on-content-click="false"
                :return-value.sync="dobDate"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dobDate"
                    label="Date of Birth"
                    required
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  ref="dobPicker"
                  v-model="dobDate"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1900-01-01"
                  @change="save"
                  readonly
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-file-input
                chips
                show-size
                truncate-length="15"
                accept="image/*"
                label="Upload Photo"
                disabled
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-checkbox
                v-model="checkConfirm"
                label="I confirm that all of the above is complete"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <br />
      <v-row justify="center" class="pb-6">
        <v-btn @click="createCredential()" :disabled="!checkConfirm"
          >Submit</v-btn
        >
      </v-row>
    </v-container>
    <v-container id="city-id-4" v-if="sceneConfirmation">
      <v-stepper value="3">
        <v-stepper-header>
          <v-stepper-step step="1" complete> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2" complete>
            SiriusID Connection</v-stepper-step
          >
          <v-divider />
          <v-stepper-step step="3"> CityID Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />

      <v-row justify="center">
        <div class="text-body-1 px-6 py-4 text-center">
          Please scan or tap on the following QR Code to confirm your
          application for your City ID
        </div>
      </v-row>
      <br />
      <v-row justify="center">
        <v-sheet color="white" elevation="2" height="300" width="300">
          <a :href="this.confirmLink" target="_blank" rel="noreferrer noopener">
            <v-img :src="this.confirmImg" :alt="this.confirmLink">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey"
                    size="100"
                    width="5"
                  />
                </v-row>
              </template>
            </v-img>
          </a>
        </v-sheet>
      </v-row>
      <br />
      <v-row justify="center">
        <v-btn @click="transitionComplete()">Complete</v-btn>
      </v-row>
      <br />
    </v-container>
    <v-container id="city-id-end" v-if="sceneComplete">
      <v-stepper value="3">
        <v-stepper-header>
          <v-stepper-step step="1" complete> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2" complete>
            SiriusID Connection</v-stepper-step
          >
          <v-divider />
          <v-stepper-step step="3" complete>
            CityID Application
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />

      <v-row justify="center">
        <div class="text-body-1 text-center px-6 pt-8">
          Congratulations on your successful application
        </div>
      </v-row>
      <v-row justify="center">
        <div class="text-body-1 text-center px-6 py-2">
          Your City ID credentials are now available on the SiriusID app
        </div>
      </v-row>
      <br />
      <v-row justify="center">
        <v-img contain src="../assets/city-id.png" max-height="200" />
      </v-row>
      <br />
      <v-row justify="center">
        <v-btn
          v-on:click="
            $emit('close-id');
            transitionReset();
          "
          >Finish</v-btn
        >
      </v-row>
    </v-container>
  </section>
</template>

<script>
import {
  ApiNode,
  CredentialRequestMessage,
  LoginRequestMessage,
  VerifytoLogin,
  Credentials,
} from "siriusid-sdk";
import { Listener } from "tsjs-xpx-chain-sdk";
import { SiriusIdService } from "../services/siriusid";
// import {
//   BlockchainNetworkConnection,
//   ConnectionConfig,
//   Protocol,
//   IpfsConnection,
//   Uploader,
//   UploadParameter,
//   BlockchainNetworkType,
//   Uint8ArrayParameterData,
// } from "tsjs-chain-xipfs-sdk";
// import sampleImage from "@/assets/city-id.png";

export default {
  name: "CityId",
  data: function () {
    return {
      sceneIntro: true,
      sceneWelcome: false,
      sceneScan: false,
      sceneForm: false,
      sceneConfirmation: false,
      sceneComplete: false,

      dobMenu: false,
      dobDate: "1989-11-12",

      firstName: "John",
      lastName: "Lee",
      address: "6 Rainbow St, Meadowbank, Colorado, USA",
      phoneNumber: "+1 234 567 890",
      email: "john123@example.com",

      checkConfirm: false,

      photoLink: "../assets/city-employment.png",
      photoData: null,

      // SiriusID Variables
      sidService: new SiriusIdService(),
      connected: false,
      listener: null,
      subscribe: null,
      qrImg: null,
      qrLink: null,
      confirmImg: null,
      confirmLink: null,
      sessionToken: null,
    };
  },
  methods: {
    transitionReset() {
      // Scenes
      this.sceneIntro = true;
      this.sceneWelcome = false;
      this.sceneScan = false;
      this.sceneForm = false;
      this.sceneConfirmation = false;
      this.sceneComplete = false;

      // Form
      this.checkConfirm = false;

      // Sirius ID
      this.listener.close();
      this.listener = null;
      this.subscribe = null;
      this.connected = false;
      this.qrImg = null;
      this.qrLink = null;
      this.confirmImg = null;
      this.confirmLink = null;
      this.sidService = new SiriusIdService();
      this.sessionToken = null;
    },
    transitionEnter() {
      this.sceneIntro = false;
      this.sceneWelcome = true;
    },
    transitionScan() {
      this.sceneWelcome = false;
      this.sceneScan = true;
    },
    transitionConfirm() {
      this.sceneForm = false;
      this.sceneConfirmation = true;
    },
    transitionComplete() {
      // this.sceneForm = false;
      this.sceneConfirmation = false;
      this.sceneComplete = true;
    },
    save(dobDate) {
      this.$refs.dobMenu.save(dobDate);
    },
    async loginRequest() {
      this.connected = false;
      const loginRequestMessage = LoginRequestMessage.create(
        this.sidService.idPubKey,
        []
      );
      const sessionToken = loginRequestMessage.getSessionToken();
      this.sessionToken = loginRequestMessage.getSessionToken();
      this.qrImg = await loginRequestMessage.generateQR();
      this.qrLink = await loginRequestMessage.universalLink();

      this.createConnection(sessionToken);
      let interval = setInterval(async () => {
        const listenerAsAny = this.listener;
        if (
          listenerAsAny.webSocket.readyState !== 1 &&
          listenerAsAny.webSocket.readyState !== 0 &&
          !this.connected
        ) {
          this.listener = new Listener(this.sidService.ws, WebSocket);
          this.createConnection(sessionToken);
        } else if (listenerAsAny.webSocket.readyState == 1 && !this.connected) {
          //
        } else if (this.connected) {
          clearInterval(interval);
        }
      }, 1000);
    },
    async createConnection(sessionToken) {
      this.listener.open().then(() => {
        this.subscribe = this.listener
          .confirmed(this.sidService.idAddress)
          .subscribe((transaction) => {
            VerifytoLogin.verify(
              transaction,
              sessionToken,
              [],
              this.sidService.idPriKey
            ).then(async (verify) => {
              if (verify) {
                this.connected = true;
                this.sidService.address = transaction.signer.address.plain();
                this.sidService.pubKey = transaction.signer.publicKey;
                this.sidService.credentialInfo = VerifytoLogin.credentials;
                this.subscribe.unsubscribe();
                this.sceneScan = false;
                this.sceneForm = true;
              } else {
                //
              }
            });
          });
      });
    },
    async createCredential() {
      // const chainApi = new TransactionHttp(this.sidService.api);
      // const sender = Account.createFromPrivateKey(
      //   this.sidService.idPriKey,
      //   this.sidService.networkType
      // );
      // const generationHash = this.sidService.generationHash;
      // const chain = {
      //   apiHost: this.sidService.endpoint,
      //   apiPort: 443,
      //   apiProtocol: "https",
      // };
      // const storage = {
      //   host: this.sidService.storageEndpoint,
      //   options: { protocol: "https" },
      //   port: 5443,
      // };

      // const connectionConfig = ConnectionConfig.createWithLocalIpfsConnection(
      //   new BlockchainNetworkConnection(
      //     BlockchainNetworkType.TEST_NET,
      //     chain.apiHost,
      //     chain.apiPort,
      //     Protocol.HTTPS
      //   ),
      //   new IpfsConnection(storage.host, storage.port, storage.options)
      // );

      // const uploader = new Uploader(connectionConfig);
      // const image = this.convertDataToBinary(sampleImage);
      // const metaParams = Uint8ArrayParameterData.create(image);
      // const uploadParams = UploadParameter.createForUint8ArrayUpload(
      //   metaParams,
      //   sender.idPriKey
      // )
      //   .withTransactionMosaics([])
      //   .build();
      // const uploadResult = await uploader.upload(uploadParams);
      // const photoHash = uploadResult.transactionHash;

      const credentialData = new Map();
      credentialData.set("First Name", this.firstName);
      credentialData.set("Last Name", this.lastName);
      credentialData.set("Date of Birth", this.dobDate);
      credentialData.set("Address", this.address);
      credentialData.set("Phone Number", this.phoneNumber);
      credentialData.set("Email", this.email);
      // credentialData.set("Photo", "photoHash");

      const credential = Credentials.create(
        "proximaxId",
        "ProximaXCity ID",
        "ProximaXCity ID Department",
        "/assets/icon-credentials-employment-pass.svg",
        [],
        credentialData,
        "",
        Credentials.authCreate(credentialData, this.sidService.idPriKey)
      );

      // credential.addAuthOwner(Credentials.authCreate(credential.getContent(), this.sidService.idPriKey))

      // const credentialResponse = CredentialResponseMessage.create(
      //   this.sidService.idPubKey,
      //   this.sessionToken,
      //   credential,
      // );

      // console.log("Credential Response: ", credentialResponse)
      // const adminAccount = PublicAccount.createFromPublicKey(this.sidService.idPubKey, this.sidService.networkType)
      // const encryptedMessage = EncryptedMessage.create("123", adminAccount, this.sidService.idPriKey);
      // const adminAddress = ""

      const credentialMessage = CredentialRequestMessage.create(credential);
      this.confirmImg = await credentialMessage.generateQR();
      this.confirmLink = await credentialMessage.universalLink();

      this.sceneForm = false;
      this.sceneConfirmation = true;
    },
    refresh() {
      this.subscribe.unsubscribe();
      this.loginRequest();
    },
    // readFile(input) {
    //   const reader = new FileReader();
    //   reader.onload = (e) => {
    //     this.photoData = reader.result;
    //   };
    // },
    convertDataToBinary(input) {
      const base64Marker = ";base64,";
      const base64Index = input.indexOf(base64Marker) + base64Marker.length;
      const base64Input = input.substring(base64Index);
      const rawInput = window.atob(base64Input);
      const data = new Uint8Array(new ArrayBuffer(rawInput.length));
      for (let i = 0; i < rawInput.length; i++) {
        data[i] = rawInput.charCodeAt(i);
      }

      return data;
    },
  },
  mounted() {
    ApiNode.apiNode = this.sidService.api;
    ApiNode.networkType = this.sidService.networkType;
    this.listener = new Listener(this.sidService.ws, WebSocket);
    this.loginRequest();
  },
  computed: {
    nameRules() {
      const nameRules = [];

      const noSpaces = (v) =>
        (v || "").indexOf(" ") < 0 || "No spaces are allowed";

      const noNumbers = (v) =>
        /[a-z]+$/.test(v) || "Name must only contain only alphabets";

      nameRules.push(noSpaces);
      nameRules.push(noNumbers);

      return nameRules;
    },
    addressRules() {
      const addressRules = [];

      return addressRules;
    },
    phoneRules() {
      const phoneRules = [];

      // const onlyNumbers = (v) =>
      //   /[0-9]{8,}$/.test(v) || "Phone numbers must be at least 8-digits";

      // phoneRules.push(onlyNumbers);

      return phoneRules;
    },
    emailRules() {
      const emailRules = [];

      // Sourced from emailregex.com with some linting
      const emailRegex = (v) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "Email must be valid";

      emailRules.push(emailRegex);

      return emailRules;
    },
  },
  watch: {
    dobMenu(val) {
      val && setTimeout(() => (this.$refs.dobPicker.activePicker = "YEAR"));
    },
  },
};
</script>
