<template>
  <section id="city">
    <v-img src="../assets/bg-sky.png">
      <v-container fill-height>
        <v-row>
          <v-col
            v-for="({ building, icon, name, func, completion }, i) in locations"
            :key="i"
            cols="12"
            md="4"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                class="py-10 my-10 mx-15 transparent-card"
                flat
                :disabled="checkCompletion(completion)"
                height="250"
              >
                <div>
                  <v-img
                    contain
                    :src="require(`../assets/${building}`)"
                    height="200"
                    @click="handleCardLoad(func)"
                    class="city-icon"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="transition-fast-in-fast-out v-card--reveal d-flex"
                        style="height: 100%"
                      >
                        <v-img
                          :src="require(`../assets/${icon}`)"
                          height="200"
                          contain
                        />
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <br />
                  <v-scroll-y-reverse-transition>
                    <div v-if="hover" class="darken-2" style="height: 100%">
                      <v-card-title
                        class="justify-center font-weight-black text-uppercase city-icon"
                        v-text="name"
                        @click="handleCardLoad(func)"
                      />
                      <br />
                      <!-- <v-card-actions class="justify-center">
                        <v-btn text @click="handleCardLoad(func)">Visit</v-btn>
                      </v-card-actions> -->
                    </div>
                  </v-scroll-y-reverse-transition>
                </div>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer cols="3" />
          <v-col cols="6">
            <v-row justify="center">
              <v-btn
                v-on:click="$emit('finish-city-button')"
                :disabled="!completeImmunity"
                >Finish</v-btn
              >
            </v-row>
          </v-col>
          <v-spacer cols="3" />
        </v-row>
        <v-row justify="center">
          <v-dialog v-model="inId" persistent fullscreen>
            <v-card>
              <CityId v-on:close-id="closeId" />
            </v-card>
          </v-dialog>
          <v-dialog v-model="inPassport" persistent fullscreen>
            <v-card>
              <CityPassport v-on:close-passport="closePassport" />
            </v-card>
          </v-dialog>
          <v-dialog v-model="inBank" persistent fullscreen>
            <v-card>
              <CityBank v-on:close-bank="closeBank" />
            </v-card>
          </v-dialog>
          <v-dialog v-model="inEmployment" persistent fullscreen>
            <v-card>
              <CityEmployment v-on:close-employment="closeEmployment" />
            </v-card>
          </v-dialog>
          <v-dialog v-model="inVisa" persistent fullscreen>
            <v-card>
              <CityVisa v-on:close-visa="closeVisa" />
            </v-card>
          </v-dialog>
          <v-dialog v-model="inImmunity" persistent fullscreen>
            <v-card>
              <CityImmunity v-on:close-immunity="closeImmunity" />
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-img>
  </section>
</template>

<style scoped>
.city-building {
  background: #f98045;
}
.city-icon {
  cursor: pointer;
}
.transparent-card {
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: white !important;
}
</style>

<script>
import CityId from "./CityId";
import CityPassport from "./CityPassport";
import CityVisa from "./CityVisa";
import CityImmunity from "./CityImmunity";
import CityEmployment from "./CityEmployment";
import CityBank from "./CityBank";

export default {
  name: "City",

  components: {
    CityId,
    CityPassport,
    CityVisa,
    CityImmunity,
    CityEmployment,
    CityBank,
  },

  created: function () {
    window.scrollTo(0, 0);
  },

  data: function () {
    return {
      inId: false,
      inPassport: false,
      inVisa: false,
      inImmunity: false,
      inEmployment: false,
      inBank: false,

      // Variables to track whether a specific task has been completed
      initialCompletion: true,
      completeId: false,
      completePassport: false,
      completeVisa: false,
      completeImmunity: false,
      completeEmployment: false,
      completeBank: false,

      locations: [
        {
          building: "building-id.svg",
          icon: "city-id.png",
          folder: "id",
          name: "City ID",
          func: "openId",
          completion: "initialCompletion",
        },
        {
          building: "building-passport.svg",
          icon: "city-passport.png",
          folder: "passport",
          name: "City Passport",
          func: "openPassport",
          completion: "completeId",
        },
        {
          building: "building-bank.svg",
          icon: "city-bank.png",
          folder: "bank",
          name: "Zamo Bank",
          func: "openBank",
          completion: "completePassport",
        },
        {
          building: "building-employment.svg",
          icon: "city-employment.png",
          folder: "employment",
          name: "Office",
          func: "openEmployment",
          completion: "completeBank",
        },
        {
          building: "building-visa.svg",
          icon: "city-visa.png",
          folder: "visa",
          name: "Travel Agency",
          func: "openVisa",
          completion: "completeEmployment",
        },
        {
          building: "building-immunity.svg",
          icon: "city-immunity.png",
          folder: "immunity",
          name: "Clinic",
          func: "openImmunity",
          completion: "completeVisa",
        },
      ],
    };
  },

  methods: {
    handleCardLoad(function_name) {
      this[function_name]();
    },

    checkCompletion(completion) {
      return !this[completion];
    },

    openId() {
      this.inId = true;
    },

    closeId() {
      this.inId = false;
      this.completeId = true;
    },

    openPassport() {
      this.inPassport = true;
    },

    closePassport() {
      this.inPassport = false;
      this.completePassport = true;
    },

    openVisa() {
      this.inVisa = true;
    },

    closeVisa() {
      this.inVisa = false;
      this.completeVisa = true;
    },

    openImmunity() {
      this.inImmunity = true;
    },

    closeImmunity() {
      this.inImmunity = false;
      this.completeImmunity = true;
    },

    openEmployment() {
      this.inEmployment = true;
    },

    closeEmployment() {
      this.inEmployment = false;
      this.completeEmployment = true;
    },

    openBank() {
      this.inBank = true;
    },

    closeBank() {
      this.inBank = false;
      this.completeBank = true;
    },
  },
};
</script>
