<template>
  <section id="city-employment">
    <!-- <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-spacer />
      <v-btn
        icon
        v-on:click="
          $emit('close-employment');
          transitionReset();
        "
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-app-bar> -->
    <v-container id="city-employment-1" v-if="sceneIntro">
      <v-stepper value="1">
        <v-stepper-header>
          <v-stepper-step step="1"> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2"> SiriusID Connection</v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> Employ ID Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />
      <v-row justify="center" no-gutters>
        <v-img
          src="../assets/city/employment/scene.png"
          min-height="65vh"
          max-height="65vh"
          contain
        />
      </v-row>
      <br />
      <v-row justify="center">
        <div class="text-body mx-6">
          <strong>Visitor</strong>: Now that I have a bank account, it is time
          to start looking for a job.
        </div>
      </v-row>

      <br />

      <v-row justify="center" class="pb-6">
        <v-btn @click="transitionEnter()"
          >Enter <v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </v-row>
    </v-container>
    <v-container id="city-employment-1-1" v-if="sceneWelcome">
      <v-stepper value="1">
        <v-stepper-header>
          <v-stepper-step step="1"> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2"> SiriusID Connection</v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> Employ ID Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />
      <v-row justify="center">
        <v-img
          src="../assets/city/employment/staff.png"
          contain
          max-width="525"
          height="500"
          class="hidden-sm-and-down"
        />
        <v-img
          src="../assets/city/employment/staff.png"
          contain
          max-width="300"
          height="500"
          class="hidden-md-and-up"
        />
      </v-row>
      <br />
      <v-row justify="center">
        <div class="text-body mx-6">
          <strong>Staff</strong>: Welcome to Employ ID. To help you get a job,
          we will need your City ID.
        </div>
      </v-row>

      <br />

      <v-row justify="center" class="pb-6">
        <!-- <v-btn @click="transitionReset()"
          ><v-icon>mdi-chevron-left</v-icon>Back</v-btn
        > -->
        <!-- <v-btn @click="skipConversations()">Skip</v-btn> -->
        <v-btn @click="transitionScan()"
          >Next <v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </v-row>
    </v-container>
    <v-container id="city-employment-2" v-if="sceneScan">
      <v-stepper value="2">
        <v-stepper-header>
          <v-stepper-step step="1" complete> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2"> SiriusID Connection</v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> Employ ID Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />
      <v-row justify="center">
        <div class="text-body-1 text-center px-6 py-4">
          To apply for your Employ ID, please scan the following QR Code
        </div>
      </v-row>
      <br />
      <v-row justify="center">
        <v-sheet color="white" elevation="2" height="300" width="300">
          <a :href="this.qrLink" target="_blank" rel="noreferrer noopener">
            <v-img :src="this.qrImg" :alt="this.qrLink">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey"
                    size="100"
                    width="5"
                  />
                </v-row>
              </template>
            </v-img>
          </a>
        </v-sheet>
      </v-row>
      <br />
      <v-row justify="center">
        <v-btn text><v-icon>mdi-refresh</v-icon>Refresh Code</v-btn>
      </v-row>
      <br />
    </v-container>
    <v-container id="city-employment-3" v-if="sceneConfirmation">
      <v-stepper value="3">
        <v-stepper-header>
          <v-stepper-step step="1" complete> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2" complete>
            SiriusID Connection</v-stepper-step
          >
          <v-divider />
          <v-stepper-step step="3"> Employ ID Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />
      <v-row justify="center">
        <div class="text-body-1 text-center px-6 py-4">
          Please scan or tap on the following QR Code to confirm your
          application for your Employ ID
        </div>
      </v-row>
      <br />
      <v-row justify="center">
        <v-sheet color="white" elevation="2" height="300" width="300">
          <a :href="this.confirmLink" target="_blank" rel="noreferrer noopener">
            <v-img :src="this.confirmImg" :alt="this.confirmLink">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey"
                    size="100"
                    width="5"
                  />
                </v-row>
              </template>
            </v-img>
          </a>
        </v-sheet>
      </v-row>
      <br />
      <v-row justify="center">
        <v-btn @click="transitionComplete()"
          ><v-icon>mdi-share</v-icon>Complete</v-btn
        >
      </v-row>
    </v-container>
    <v-container id="city-employment-end" v-if="sceneComplete">
      <v-stepper value="3">
        <v-stepper-header>
          <v-stepper-step step="1" complete> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2" complete>
            SiriusID Connection</v-stepper-step
          >
          <v-divider />
          <v-stepper-step step="3" complete>
            Employ ID Application
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />
      <v-row justify="center">
        <div class="text-body-1 text-center px-6 py-2">
          Congratulations on your successful application
        </div>
      </v-row>
      <v-row justify="center">
        <div class="text-body-1 text-center px-6 py-2">
          Your Employ ID is now available on the SiriusID app
        </div>
      </v-row>
      <br />
      <v-row justify="center">
        <v-img contain src="../assets/city-employment.png" max-height="200" />
      </v-row>
      <br />
      <v-row justify="center" class="pb-6">
        <v-btn
          v-on:click="
            $emit('close-employment');
            transitionReset();
          "
          >Finish</v-btn
        >
      </v-row>
      <!-- <br />
      <br />
      <v-row justify="center">
        <v-btn text>
          <div class="text-caption text-center">I can't see my Employ ID</div>
        </v-btn>
      </v-row> -->
    </v-container>
  </section>
</template>

<script>
import {
  ApiNode,
  Credentials,
  CredentialRequestMessage,
  LoginRequestMessage,
  VerifytoLogin,
} from "siriusid-sdk";
import { SiriusIdService } from "../services/siriusid";
import { Listener } from "tsjs-xpx-chain-sdk";
export default {
  name: "cityEmployment",
  data: function () {
    return {
      sceneIntro: true,
      sceneWelcome: false,
      sceneScan: false,
      sceneConfirmation: false,
      sceneComplete: false,

      sidService: new SiriusIdService(),
      listener: null,
      subscribe: null,
      connected: false,
      requiredCredentials: ["proximaxId", "bankID"],
      qrImg: null,
      qrLink: null,
      confirmImg: null,
      confirmLink: null,
    };
  },
  methods: {
    transitionReset() {
      this.sceneIntro = true;
      this.sceneWelcome = false;
      this.sceneScan = false;
      this.sceneConfirmation = false;
      this.sceneComplete = false;

      this.qrImg = null;
      this.qrLink = null;
      this.confirmImg = null;
      this.confirmLink = null;
    },
    transitionEnter() {
      this.sceneIntro = false;
      this.sceneWelcome = true;
    },
    transitionScan() {
      this.sceneWelcome = false;
      this.sceneScan = true;
    },
    transitionComplete() {
      this.sceneConfirmation = false;
      this.sceneComplete = true;
    },
    async loginRequest() {
      this.connected = false;
      const loginRequestMessage = LoginRequestMessage.create(
        this.sidService.employmentPubKey,
        this.requiredCredentials
      );
      const sessionToken = loginRequestMessage.getSessionToken();
      this.qrImg = await loginRequestMessage.generateQR();
      this.qrLink = await loginRequestMessage.universalLink();

      this.createConnection(sessionToken);
      let interval = setInterval(async () => {
        const listenerAsAny = this.listener;
        if (
          listenerAsAny.webSocket.readyState != 1 &&
          listenerAsAny.webSocket.readyState != 0 &&
          !this.connected
        ) {
          this.listener = new Listener(this.sidService.ws, WebSocket);
          this.createConnection(sessionToken);
        } else if (listenerAsAny.webSocket.readyState == 1 && !this.connected) {
          //
        } else if (this.connected) {
          clearInterval(interval);
        }
      }, 1000);
    },
    async createConnection(sessionToken) {
      this.listener.open().then(() => {
        this.subscribe = this.listener
          .confirmed(this.sidService.employmentAddress)
          .subscribe((transaction) => {
            VerifytoLogin.verify(
              transaction,
              sessionToken,
              this.requiredCredentials,
              this.sidService.employmentPriKey
            ).then((verify) => {
              if (verify) {
                this.connected = true;
                this.sidService.address = transaction.signer.address.plain();
                this.sidService.credentialInfo = VerifytoLogin.credentials;
                this.subscribe.unsubscribe();
                this.createCredential();
              } else {
                //
              }
            });
          });
      });
    },
    async refresh() {
      this.subscribe.unsubscribe();
      this.loginRequest();
    },
    async createCredential() {
      const credentialData = new Map();
      credentialData.set(
        "First Name",
        this.sidService.credentialInfo[0].content[0][1]
      );
      credentialData.set(
        "Last Name",
        this.sidService.credentialInfo[0].content[1][1]
      );
      credentialData.set(
        "Date of Birth",
        this.sidService.credentialInfo[0].content[2][1]
      );
      credentialData.set(
        "Bank Account Number",
        this.sidService.credentialInfo[1].content[2][1]
      );
      credentialData.set("Highest Education", "Undergraduate");
      credentialData.set("Last Place of Education", "Central University");
      credentialData.set("Prior Criminal Records", "N/A");

      const credential = Credentials.create(
        "employmentID",
        "EmployID",
        "Employment IDentification",
        "/assets/employment.svg",
        [],
        credentialData,
        "",
        Credentials.authCreate(credentialData, this.sidService.employmentPriKey)
      );

      const credentialMessage = CredentialRequestMessage.create(credential);
      this.confirmImg = await credentialMessage.generateQR();
      this.confirmLink = await credentialMessage.universalLink();

      this.sceneScan = false;
      this.sceneConfirmation = true;
    },
  },
  mounted() {
    ApiNode.apiNode = this.sidService.api;
    ApiNode.networkType = this.sidService.networkType;
    this.listener = new Listener(this.sidService.ws, WebSocket);
    this.loginRequest();
  },
};
</script>
