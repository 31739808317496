<template>
  <section id="get-in-touch" class="get-in-touch white--text">
    <div class="py-12" />
    <v-container class="text-center">
      <div class="text-h2 font-weight-bold mb-3 text-uppercase">
        Get In Touch!
      </div>

      <v-responsive
        class="mx-auto title font-weight-light mb-8"
        max-width="720"
      >
        Find out more about how our products can benefit your organization now!
      </v-responsive>

      <v-divider inset /> <br />

      <div class="text-h4 font-weight-bold mb-3">For Devs:</div>
      <v-responsive class="mx-auto font-weight-light mb-8" max-width="720">
        Build with ProximaX SiriusID. Easy to use plugins available for
        development and design of software.
      </v-responsive>

      <v-btn class="ma-2 white--text font-weight-bold" color="#f98045">
        Learn More Here
        <v-icon right> mdi-chevron-right </v-icon>
      </v-btn>

      <br /><br />

      <div class="text-h4 font-weight-bold mb-3">For Enterprises:</div>
      <v-responsive class="mx-auto font-weight-light mb-8" max-width="720">
        Enjoyed the demo and ready to use SiriusID? Tailor a business solution
        and own it by using ProximaX SiriusID.
      </v-responsive>

      <v-btn class="ma-2 white--text font-weight-bold" color="#364eff">
        Visit SiriusID
        <v-icon right> mdi-chevron-right </v-icon>
      </v-btn>
    </v-container>

    <div class="py-12" />
  </section>
</template>

<style scoped>
.get-in-touch {
  height: 100%;
  background: linear-gradient(45deg, #7285f7, #00b4ff);
}
</style>

<script>
export default {
  name: "GetInTouch",

  created: function () {
    window.scrollTo(0, 0);
  },
};
</script>
