<template>
  <section id="city-visa">
    <!-- <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-spacer />
      <v-btn
        icon
        v-on:click="
          $emit('close-visa');
          transitionReset();
        "
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-app-bar> -->
    <v-container id="city-visa-1" v-if="sceneIntro">
      <v-stepper value="1">
        <v-stepper-header>
          <v-stepper-step step="1"> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2"> SiriusID Connection</v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> Visa Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />
      <v-row justify="center" no-gutters>
        <v-img
          src="../assets/city/visa/scene.png"
          min-height="65vh"
          max-height="65vh"
          contain
        />
      </v-row>

      <br />

      <v-row justify="center">
        <div class="text-body mx-6">
          <strong>Visitor</strong>: Now that I am set up with everything I need
          to get around ProximaXCity, it is time to start thinking about going
          on holiday.
        </div>
      </v-row>

      <br />

      <v-row justify="center" class="pb-6">
        <v-btn @click="transitionEnter()"
          >Enter <v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </v-row>
    </v-container>
    <v-container id="city-visa-1-1" v-if="sceneWelcome">
      <v-stepper value="1">
        <v-stepper-header>
          <v-stepper-step step="1"> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2"> SiriusID Connection</v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> Visa Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />
      <v-row justify="center">
        <v-img
          src="../assets/city/visa/staff.png"
          contain
          max-width="525"
          height="500"
          class="hidden-sm-and-down"
        />
        <v-img
          src="../assets/city/visa/staff.png"
          contain
          max-width="300"
          height="500"
          class="hidden-md-and-up"
        />
      </v-row>
      <br />
      <v-row justify="center">
        <div class="text-body mx-6">
          <strong>Travel Agent</strong>: We can assist you with your holiday
          bookings and visa application. We will need a copy of your passport
          and travel plans. going on holiday.
        </div>
      </v-row>

      <br />

      <v-row justify="center" class="pb-6">
        <!-- <v-btn @click="transitionReset()"
          ><v-icon>mdi-chevron-left</v-icon>Back</v-btn
        > -->
        <!-- <v-btn @click="skipConversations()">Skip</v-btn> -->
        <v-btn @click="transitionScan()"
          >Next <v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </v-row>
    </v-container>
    <v-container id="city-visa-2" v-if="sceneScan">
      <v-stepper value="2">
        <v-stepper-header>
          <v-stepper-step step="1"> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2"> SiriusID Connection</v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> Visa Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />
      <v-row justify="center">
        <div class="text-body-1 px-6 py-4">
          To begin the visa application process, please scan the following QR
          Code
        </div>
      </v-row>
      <br />
      <v-row justify="center">
        <v-sheet color="white" elevation="2" height="300" width="300">
          <a :href="this.qrLink" target="_blank" rel="noreferrer noopener">
            <v-img :src="this.qrImg" :alt="this.qrLink">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey"
                    size="100"
                    width="5"
                  />
                </v-row>
              </template>
            </v-img>
          </a>
        </v-sheet>
      </v-row>
      <br />
      <v-row justify="center">
        <v-btn text><v-icon>mdi-refresh</v-icon>Refresh Code</v-btn>
      </v-row>
    </v-container>
    <v-container id="city-visa-3" v-if="sceneForm">
      <v-stepper value="3">
        <v-stepper-header>
          <v-stepper-step step="1"> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2"> SiriusID Connection</v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> Visa Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />
      <v-row justify="center"
        >Please fill the following form with your travel plans</v-row
      >
      <br />
      <v-form>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="visaDates"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="visaDateRange"
                    label="Visa Validity Period"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                /></template>
                <v-date-picker
                  v-model="visaDates"
                  no-title
                  scrollable
                  range
                  disabled
                >
                  <v-spacer />
                  <v-btn text @click="dateMenu = false">Cancel</v-btn>
                  <v-btn text @click="$refs.dateMenu.save(visaDates)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="destination"
                :rules="destinationRules"
                label="Destination"
                required
                readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="entryCheckpoint"
                :rules="checkpointRules"
                label="Entry Checkpoint"
                required
                readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="exitCheckpoint"
                :rules="checkpointRules"
                label="Exit Checkpoint"
                required
                readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="tempAddress"
                :rules="addressRules"
                label="Temporary Address"
                required
                readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                :items="purposeItems"
                label="Purpose"
                v-model="reason"
                readonly
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="emergencyContact"
                :rules="contactRules"
                label="Emergency Contact"
                required
                readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-checkbox
                v-model="checkConfirm"
                label="I confirm that the above information is complete"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <br />
      <v-row justify="center">
        <v-btn @click="createCredential()" :disabled="!checkConfirm"
          >Submit</v-btn
        >
      </v-row>
    </v-container>
    <v-container id="city-visa-4" v-if="sceneConfirmation">
      <v-stepper value="3">
        <v-stepper-header>
          <v-stepper-step step="1" complete> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2" complete>
            SiriusID Connection</v-stepper-step
          >
          <v-divider />
          <v-stepper-step step="3"> Visa Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />

      <v-row justify="center">
        <div class="text-body-1 px-6 py-4 text-center">
          Please scan or tap on the following QR Code to confirm your
          application for your Travel Visa
        </div>
      </v-row>
      <br />
      <v-row justify="center">
        <v-sheet color="white" elevation="2" height="300" width="300">
          <a :href="this.confirmLink" target="_blank" rel="noreferrer noopener">
            <v-img :src="this.confirmImg" :alt="this.confirmLink">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey"
                    size="100"
                    width="5"
                  />
                </v-row>
              </template>
            </v-img>
          </a>
        </v-sheet>
      </v-row>
      <br />
      <v-row justify="center">
        <v-btn @click="transitionComplete()">Complete</v-btn>
      </v-row>
      <br />
    </v-container>
    <v-container id="city-visa-end" v-if="sceneComplete">
      <v-stepper value="3">
        <v-stepper-header>
          <v-stepper-step step="1"> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2"> SiriusID Connection</v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> Visa Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />
      <v-row justify="center">
        <div class="text-body-1 text-center px-6 py-2">
          Congratulations on your successful application
        </div>
      </v-row>
      <v-row justify="center">
        <div class="text-body-1 text-center px-6 py-2">
          Your Travel Visa is now available on the SiriusID app
        </div>
      </v-row>
      <br />
      <v-row justify="center">
        <v-img contain src="../assets/city-visa.png" max-height="200" />
      </v-row>
      <br />
      <v-row justify="center">
        <v-btn
          v-on:click="
            $emit('close-visa');
            transitionReset();
          "
          >Finish</v-btn
        >
      </v-row>
      <!-- <br />
      <br />
      <v-row justify="center">
        <v-btn text>
          <div class="text-caption text-center">I can't see my Visa</div>
        </v-btn>
      </v-row> -->
    </v-container>
  </section>
</template>

<script>
import {
  ApiNode,
  CredentialRequestMessage,
  LoginRequestMessage,
  VerifytoLogin,
  Credentials,
} from "siriusid-sdk";
import { Listener } from "tsjs-xpx-chain-sdk";
import { SiriusIdService } from "../services/siriusid";
export default {
  name: "cityVisa",
  data: function () {
    return {
      sceneIntro: true,
      sceneWelcome: false,
      sceneScan: false,
      sceneForm: false,
      sceneConfirmation: false,
      sceneComplete: false,

      dateMenu: false,
      visaDates: ["2021-01-01", "2022-12-31"],
      destination: "Japan",
      entryCheckpoint: "Narita Airport",
      exitCheckpoint: "Kansai Airport",
      tempAddress:
        "1 Chome-5-11 Minamihonmachi, Chuo Ward, Osaka, 541-0054, Japan",
      emergencyContact: "+81 080 123 123",

      checkConfirm: false,

      purposeItems: ["Leisure", "Business", "Both"],
      reason: "Leisure",

      // SiriusID Variables
      sidService: new SiriusIdService(),
      connected: false,
      listener: null,
      subscribe: null,
      requiredCredentials: ["passport"],
      qrImg: null,
      qrLink: null,
    };
  },
  methods: {
    transitionReset() {
      // Scenes
      this.sceneIntro = true;
      this.sceneWelcome = false;
      this.sceneScan = false;
      this.sceneForm = false;
      this.sceneConfirmation = false;
      this.sceneComplete = false;

      // Form
      this.checkConfirm = false;

      // Sirius ID
      this.listener.close();
      this.listener = null;
      this.subscribe = null;
      this.connected = false;
      this.qrImg = null;
      this.qrLink = null;
      this.sidService = new SiriusIdService();
    },
    transitionEnter() {
      this.sceneIntro = false;
      this.sceneWelcome = true;
    },
    transitionScan() {
      this.sceneWelcome = false;
      this.sceneScan = true;
    },
    transitionComplete() {
      this.sceneConfirmation = false;
      this.sceneComplete = true;
    },
    async loginRequest() {
      this.connected = false;
      const loginRequestMessage = LoginRequestMessage.create(
        this.sidService.visaPubKey,
        this.requiredCredentials
      );
      const sessionToken = loginRequestMessage.getSessionToken();
      this.qrImg = await loginRequestMessage.generateQR();
      this.qrLink = await loginRequestMessage.universalLink();

      this.createConnection(sessionToken);
      let interval = setInterval(async () => {
        const listenerAsAny = this.listener;
        if (
          listenerAsAny.webSocket.readyState !== 1 &&
          listenerAsAny.webSocket.readyState !== 0 &&
          !this.connected
        ) {
          this.listener = new Listener(this.sidService.ws, WebSocket);
          this.createConnection(sessionToken);
        } else if (listenerAsAny.webSocket.readyState == 1 && !this.connected) {
          //
        } else if (this.connected) {
          clearInterval(interval);
        }
      }, 1000);
    },
    async createConnection(sessionToken) {
      this.listener.open().then(() => {
        this.subscribe = this.listener
          .confirmed(this.sidService.visaAddress)
          .subscribe((transaction) => {
            VerifytoLogin.verify(
              transaction,
              sessionToken,
              this.requiredCredentials,
              this.sidService.visaPriKey
            ).then(async (verify) => {
              if (verify) {
                this.connected = true;
                this.sidService.address = transaction.signer.address.plain();
                this.sidService.pubKey = transaction.signer.publicKey;
                this.sidService.credentialInfo = VerifytoLogin.credentials;
                this.subscribe.unsubscribe();
                this.sceneScan = false;
                this.sceneForm = true;
              } else {
                //
              }
            });
          });
      });
    },
    async createCredential() {
      const credentialData = new Map();
      credentialData.set(
        "First Name",
        this.sidService.credentialInfo[0].content[0][1]
      );
      credentialData.set(
        "Last Name",
        this.sidService.credentialInfo[0].content[1][1]
      );
      credentialData.set(
        "Date of Birth",
        this.sidService.credentialInfo[0].content[2][1]
      );
      credentialData.set(
        "Height",
        this.sidService.credentialInfo[0].content[3][1]
      );
      credentialData.set(
        "Passport Number",
        this.sidService.credentialInfo[0].content[4][1]
      );
      credentialData.set(
        "Expiry Date",
        this.sidService.credentialInfo[0].content[5][1]
      );
      credentialData.set("Destination", this.destination);
      credentialData.set(
        "Visa Date",
        this.visaDates[0],
        " ~ ",
        this.visaDates[1]
      );
      credentialData.set("Purpose", this.reason);
      credentialData.set("Entry Checkpoint", this.entryCheckpoint);
      credentialData.set("Exit Checkpoint", this.exitCheckpoint);
      credentialData.set("Temporary Address", this.tempAddress);
      credentialData.set("Emergency Contact", this.emergencyContact);

      const credential = Credentials.create(
        "visa",
        "Express Travel Visa",
        "Express Visa Agency",
        "/assets/icon-credentials-e-visa-country-a.svg",
        [],
        credentialData,
        "",
        Credentials.authCreate(credentialData, this.sidService.visaPriKey)
      );

      const credentialMessage = CredentialRequestMessage.create(credential);
      this.confirmImg = await credentialMessage.generateQR();
      this.confirmLink = await credentialMessage.universalLink();

      this.sceneForm = false;
      this.sceneConfirmation = true;
    },
    refresh() {
      this.subscribe.unsubscribe();
      this.loginRequest();
    },
  },
  mounted() {
    ApiNode.apiNode = this.sidService.api;
    ApiNode.networkType = this.sidService.networkType;
    this.listener = new Listener(this.sidService.ws, WebSocket);
    this.loginRequest();
  },
  computed: {
    visaDateRange() {
      return this.visaDates.join(" ~ ");
    },
    destinationRules() {
      const destinationRules = [];
      return destinationRules;
    },
    checkpointRules() {
      const checkpointRules = [];
      return checkpointRules;
    },
    addressRules() {
      const addressRules = [];
      return addressRules;
    },
    contactRules() {
      const contactRules = [];
      return contactRules;
    },
  },
};
</script>
