<template>
  <v-footer min-height="20">
    <v-row justify="center">
      <div class="text-caption">
        Copyright © 2020 ProximaX®. All Rights Reserved.
      </div>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
