<template>
  <section id="howto" class="howto">
    <div class="py-12" />
    <v-container>
      <div
        class="text-h2 font-weight-bold mb-3 text-uppercase text-center white--text"
      >
        How To
      </div>
      <v-responsive class="mx-auto mb-12" width="56">
        <v-divider class="mb-1" />
        <v-divider />
        <v-divider />
      </v-responsive>

      <v-row>
        <v-col
          v-for="({ src, title, text }, i) in howto"
          :key="i"
          cols="12"
          md="4"
        >
          <v-img
            :src="require(`../assets/${src}`)"
            class="mb-4"
            height="275"
            max-width="100%"
            contain
          />

          <div
            class="text-h5 font-weight-black white--text mb-4 text-uppercase text-center"
            v-text="title"
          />
          <div
            class="text-body-1 font-weight-light white--text mb-5 text-center"
            v-text="text"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-spacer cols="3" />
        <v-col cols="6">
          <v-row justify="center">
            <v-btn v-on:click="$emit('start-city-button')">Continue</v-btn>
          </v-row>
        </v-col>
        <v-spacer cols="3" />
      </v-row>
    </v-container>
    <div class="py-12" />
  </section>
</template>

<style scoped>
.howto {
  height: 100%;
  background: linear-gradient(45deg, rgb(114, 133, 247), rgb(0, 180, 255));
}
</style>

<script>
export default {
  name: "HowTo",

  created: function () {
    window.scrollTo(0, 0);
  },

  data: () => ({
    howto: [
      {
        src: "howto-1.png",
        title: "Get Your CityID",
        text:
          "CityID is the main identity document in ProximaXCity that allows you access to the services available",
      },
      {
        src: "howto-2.png",
        title: "Store Verified Credentials",
        text:
          "Each verified identity document issued to you is stored in the SiriudID app, allowing you to have full over sight and control of your private documents",
      },
      {
        src: "howto-3.png",
        title: "Explore ProximaX City",
        text:
          "Continue to explore ProximaXCity and collect all the credentials necessary to be a bona fide ProximaXCity citizen",
      },
    ],
  }),
};
</script>
