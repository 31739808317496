<template>
  <section id="logo" class="logo">
    <v-img min-height="100vh" max-height="100vh" src="../assets/bg-sky.png">
      <v-container fill-height>
        <v-row justify="center">
          <v-spacer cols="1" />
          <v-col cols="10">
            <v-row>
              <v-img contain src="../assets/logo.png" max-height="180" />
            </v-row>
            <br />
            <v-row justify="center">
              <v-btn v-on:click="$emit('get-started-button')"
                >Get Started</v-btn
              >
            </v-row>
          </v-col>
          <v-spacer cols="1" />
        </v-row>
      </v-container>
    </v-img>
  </section>
</template>

<style scoped>
</style>

<script>
export default {
  name: "Logo",
};
</script>
