<template>
  <section id="city-passport">
    <!-- <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-spacer />
      <v-btn
        icon
        v-on:click="
          $emit('close-passport');
          transitionReset();
        "
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-app-bar> -->
    <v-container id="city-passport-1" v-if="sceneIntro">
      <v-stepper value="1">
        <v-stepper-header>
          <v-stepper-step step="1"> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2"> SiriusID Connection</v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> Passport Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />

      <v-row justify="center" no-gutters>
        <v-img
          src="../assets/city/passport/scene.png"
          min-height="65vh"
          max-height="65vh"
          contain
        />
      </v-row>

      <br />
      <v-row justify="center">
        <div class="text-body mx-6">
          <strong>Visitor</strong>: I will need a passport to travel in the
          future. Having a passport will also allow me to access some of the
          services of ProximaXCity.
        </div>
      </v-row>

      <br />

      <v-row justify="center" class="pb-6">
        <v-btn @click="transitionEnter()"
          >Enter <v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </v-row>
    </v-container>
    <v-container id="city-passport-1-1" v-if="sceneWelcome">
      <v-stepper value="1">
        <v-stepper-header>
          <v-stepper-step step="1"> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2"> SiriusID Connection</v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> Passport Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />
      <v-row justify="center">
        <v-img
          src="../assets/city/passport/staff.png"
          contain
          max-width="525"
          height="500"
          class="hidden-sm-and-down"
        />
        <v-img
          src="../assets/city/passport/staff.png"
          contain
          max-width="300"
          height="500"
          class="hidden-md-and-up"
        />
      </v-row>
      <br />
      <v-row justify="center">
        <div class="text-body mx-6">
          <strong>Officer</strong>: Welcome to the ProximaX City Immigration
          Department. To obtain a passport, please share your CityID by scanning
          the following QR Code.
        </div>
      </v-row>

      <br />

      <v-row justify="center" class="pb-6">
        <v-btn @click="transitionScan()"
          >Next <v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </v-row>
    </v-container>
    <v-container id="city-passport-2" v-if="sceneScan">
      <v-stepper value="2">
        <v-stepper-header>
          <v-stepper-step step="1" complete> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2"> SiriusID Connection</v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> Passport Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />
      <v-row justify="center">
        <div class="text-body-1 text-center px-6 py-4">
          To apply for your passport, please scan or tap on the following QR
          Code.
        </div>
      </v-row>
      <br />
      <v-row justify="center">
        <v-sheet color="white" elevation="2" height="300" width="300">
          <a :href="this.qrLink" target="_blank" rel="noreferrer noopener">
            <v-img :src="this.qrImg" :alt="this.qrLink">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey"
                    size="100"
                    width="5"
                  />
                </v-row>
              </template>
            </v-img>
          </a>
        </v-sheet>
      </v-row>
      <br />
      <v-row justify="center">
        <v-btn text @click="refresh()"
          ><v-icon>mdi-refresh</v-icon>Refresh Code</v-btn
        >
      </v-row>
    </v-container>
    <v-container id="city-passport-4" v-if="sceneConfirmation">
      <v-stepper value="3">
        <v-stepper-header>
          <v-stepper-step step="1" complete> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2" complete>
            SiriusID Connection</v-stepper-step
          >
          <v-divider />
          <v-stepper-step step="3"> Passport Application </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />
      <v-row justify="center">
        <div class="text-body-1 text-center px-6 py-4">
          Please scan or tap on the following QR Code to confirm your
          application for your Passport
        </div>
      </v-row>
      <br />
      <v-row justify="center">
        <v-sheet color="white" elevation="2" height="300" width="300">
          <a :href="this.confirmLink" target="_blank" rel="noreferrer noopener">
            <v-img :src="this.confirmImg" :alt="this.confirmLink">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey"
                    size="100"
                    width="5"
                  />
                </v-row>
              </template>
            </v-img>
          </a>
        </v-sheet>
      </v-row>
      <br />
      <v-row justify="center">
        <v-btn @click="transitionComplete()"
          ><v-icon>mdi-share</v-icon>Complete</v-btn
        >
      </v-row>
    </v-container>
    <v-container id="city-passport-end" v-if="sceneComplete">
      <v-stepper value="3">
        <v-stepper-header>
          <v-stepper-step step="1" complete> Introduction </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2" complete>
            SiriusID Connection</v-stepper-step
          >
          <v-divider />
          <v-stepper-step step="3" complete>
            Passport Application
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <br />
      <v-row justify="center">
        <div class="text-body-1 text-center px-6 py-2">
          Congratulations on your successful application
        </div>
      </v-row>
      <v-row justify="center">
        <div class="text-body-1 text-center px-6 py-2">
          Your Passport is now available on the SiriusID app
        </div>
      </v-row>
      <br />
      <v-row justify="center">
        <v-img contain src="../assets/city-passport.png" max-height="200" />
      </v-row>
      <br />
      <v-row justify="center">
        <v-btn
          v-on:click="
            $emit('close-passport');
            transitionReset();
          "
          >Finish</v-btn
        >
      </v-row>
    </v-container>
  </section>
</template>

<script>
import {
  ApiNode,
  CredentialRequestMessage,
  LoginRequestMessage,
  VerifytoLogin,
  Credentials,
} from "siriusid-sdk";
import { Listener } from "tsjs-xpx-chain-sdk";
import { SiriusIdService } from "../services/siriusid";
export default {
  name: "cityPassport",
  data: function () {
    return {
      sceneIntro: true,
      sceneWelcome: false,
      sceneScan: false,
      sceneConfirmation: false,
      sceneComplete: false,

      sidService: new SiriusIdService(),
      listener: null,
      subscribe: null,
      connected: false,
      requiredCredentials: ["proximaxId"],
      qrImg: null,
      qrLink: null,
      confirmImg: null,
      confirmLink: null,
    };
  },
  methods: {
    transitionReset() {
      this.sceneIntro = true;
      this.sceneWelcome = false;
      this.sceneScan = false;
      this.sceneConfirmation = false;
      this.sceneComplete = false;

      this.qrImg = null;
      this.qrLink = null;
      this.confirmImg = null;
      this.confirmLink = null;
    },
    transitionEnter() {
      this.sceneIntro = false;
      this.sceneWelcome = true;
    },
    transitionScan() {
      this.sceneWelcome = false;
      this.sceneScan = true;
    },
    transitionComplete() {
      this.sceneConfirmation = false;
      this.sceneComplete = true;
    },
    async loginRequest() {
      this.connected = false;
      const loginRequestMessage = LoginRequestMessage.create(
        this.sidService.passportPubKey,
        this.requiredCredentials
      );
      const sessionToken = loginRequestMessage.getSessionToken();
      this.qrImg = await loginRequestMessage.generateQR();
      this.qrLink = await loginRequestMessage.universalLink();

      this.createConnection(sessionToken);
      let interval = setInterval(async () => {
        const listenerAsAny = this.listener;
        if (
          listenerAsAny.webSocket.readyState != 1 &&
          listenerAsAny.webSocket.readyState != 0 &&
          !this.connected
        ) {
          this.listener = new Listener(this.sidService.ws, WebSocket);
          this.createConnection(sessionToken);
        } else if (listenerAsAny.webSocket.readyState == 1 && !this.connected) {
          //
        } else if (this.connected) {
          clearInterval(interval);
        }
      }, 1000);
    },
    async createConnection(sessionToken) {
      this.listener.open().then(() => {
        this.subscribe = this.listener
          .confirmed(this.sidService.passportAddress)
          .subscribe((transaction) => {
            VerifytoLogin.verify(
              transaction,
              sessionToken,
              this.requiredCredentials,
              this.sidService.passportPriKey
            ).then((verify) => {
              if (verify) {
                this.connected = true;
                this.sidService.address = transaction.signer.address.plain();
                this.sidService.credentialInfo = VerifytoLogin.credentials;
                this.subscribe.unsubscribe();
                this.createCredential();
              } else {
                console.log("Transaction does not match");
              }
            });
          });
      });
    },
    async refresh() {
      this.subscribe.unsubscribe();
      this.loginRequest();
    },
    async createCredential() {
      const credentialData = new Map();
      credentialData.set(
        "First Name",
        this.sidService.credentialInfo[0].content[0][1]
      );
      credentialData.set(
        "Last Name",
        this.sidService.credentialInfo[0].content[1][1]
      );
      credentialData.set(
        "Date of Birth",
        this.sidService.credentialInfo[0].content[2][1]
      );
      credentialData.set("Height", "175");
      credentialData.set("Passport Number", "1234567890");
      credentialData.set("Expiry Date", "2030-01-01");

      const credential = Credentials.create(
        "passport",
        "ProximaXCity Passport",
        "ProximaXCity Passport Department",
        "/assets/icon-credentials-digital-passport.svg",
        [],
        credentialData,
        "",
        Credentials.authCreate(credentialData, this.sidService.passportPriKey)
      );

      const credentialMessage = CredentialRequestMessage.create(credential);
      this.confirmImg = await credentialMessage.generateQR();
      this.confirmLink = await credentialMessage.universalLink();

      this.sceneScan = false;
      this.sceneConfirmation = true;
    },
  },
  mounted() {
    ApiNode.apiNode = this.sidService.api;
    ApiNode.networkType = this.sidService.networkType;
    this.listener = new Listener(this.sidService.ws, WebSocket);
    this.loginRequest();
  },
};
</script>
